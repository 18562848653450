import { ArrowRightIcon, PhoneIcon, MailIcon } from "@heroicons/react/solid";
import { useState } from "react";
import DSGStore from "../DSGStore";

const InputField = (props: any) => {
	return (
		<input
			value={props.value}
			onChange={(e) => props.onChange(e.target.value)}
			className="rounded-xl border-none bg-ds-gray-light p-2 pl-4 shadow-sm transition duration-500 focus:border-ds-yellow focus:ring-ds-yellow"
			placeholder={props.placeHolder}
			type={props.type}
		/>
	);
};

const ContactInfo = ({ location, address, person, phone, email }: {
	location: string;
	address: string[];
	person: string;
	phone: string;
	email: string;
}) => (
	<div className="flex flex-col items-center mb-8">
		<h2 className="mt-6 text-lg text-ds-yellow">{location}</h2>
		<div className="text-center mb-4">
			{address.map((line, i) => (
				<div key={i}>{line}</div>
			))}
		</div>
		<div className="text-center">
			<div className="text-ds-yellow text-sm mb-1">Ansprechpartner</div>
			{person}<br />
			<div className="flex items-center justify-center gap-1.5 mt-1">
				<PhoneIcon className="h-3.5 w-3.5 text-ds-yellow flex-shrink-0" />
				<a href={`tel:${phone.replace(/\s/g, '')}`} className="hover:text-ds-yellow transition-colors pr-4">
					{phone}
				</a>
			</div>
			<div className="inline-flex items-center justify-center gap-1.5 mt-1">
				<MailIcon className="h-3.5 w-3.5 text-ds-yellow flex-shrink-0" />
				<a href={`mailto:${email}`} className="text-ds-yellow break-all">
					{email}
				</a>
			</div>
		</div>
	</div>
);

const Contact = () => {
	const [sending, setSending] = useState(false);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [telefon, setTelefon] = useState("");
	const [company, setCompany] = useState("");
	const [message, setMessage] = useState("");

	const isAnyEmpty = (values: string[]) => 
		values.some(value => value.trim().length === 0);

	const buildMessage = (name: string, email: string, telefon: string, company: string, message: string) => {
		let final = "";

		final += `Name: ${name}\n`;
		final += `Email: ${email}\n`;
		final += `Telefon: ${telefon}\n`;
		final += `Firma: ${company}\n`;
		final += `Nachricht: \n${message}`;

		return final;
	}

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (isAnyEmpty([name, email, telefon, company, message])) {
			DSGStore.update(state => {
				state.messageModalOpen = true
				state.messageModalTitle = "Bitte fülle alle Felder aus!"
				state.messageModalMessage = "Um die Nachricht versenden zu können, müssen alle Felder ausgefüllt sein."
			})
			return;
		}

		setSending(true)

		// Askrella Notify, tested 23.04.2024
		const body = {
			subject: "DS Gesellschaft - Kontaktformular",
			message: buildMessage(name, email, telefon, company, message),
			routing: {
				organization_id: "a9ed19f9-05c8-4c3d-8adb-7ea9d79a7e80",
				user_id: "a9ed19f9-05c8-4c3d-8adb-7ea9d79a7e80",
				event_name: "askrella.notify.contact"
			}
		}

		fetch(
			"https://notify.askrella.agency/v1/contact",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			}
		)
			.then(response => {
				if (response.status !== 200) {
					throw new Error();
				}

				setName("");
				setEmail("");
				setTelefon("");
				setCompany("");
				setMessage("");		

				setSending(false);

				DSGStore.update(state => {
					state.messageModalOpen = true
					state.messageModalTitle = "Nachricht gesendet!"
					state.messageModalMessage = "Vielen Dank für Ihre Nachricht. Wir werden uns so schnell wie möglich bei Ihnen melden."
				})
			})
			.catch(error => {
				console.log(error);

				setSending(false);

				DSGStore.update(state => {
					state.messageModalOpen = true
					state.messageModalTitle = "Es ist ein Fehler aufgetreten!"
					state.messageModalMessage = "Leider ist beim Senden der Nachricht ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
				})
			});
	};

	return (
		<div
			id="contact"
			className="relative flex flex-col bg-ds-gray-dark pl-12 pr-12 pt-12 text-white md:p-24 xl:px-44"
		>
			<h1 className="z-10 text-5xl md:text-6xl">
				Ihr Weg zu <span className="text-ds-yellow">uns</span>
			</h1>
			<p className="mt-5 z-10 text-xl lg:text-2xl uppercase tracking-[0.25em] opacity-20">
				Kontaktieren Sie uns
			</p>
			<div className="flex flex-col-reverse md:flex-row mt-16 2xl:px-18 2xl:pr-36">
				<div className="flex flex-col items-center m-10 lg:mr-28 mb-14">
					<img src="/assets/logo.png" alt="logo" className="h-[150px]"/>
					
					<ContactInfo 
						location="Deutschland"
						address={[
							"Altkötzschenbroda 55",
							"01445 Radebeul"
						]}
						person="Erik Hösel"
						phone="+49 162 9824319"
						email="erik.hoesel@ds-gesellschaft.ch"
					/>
					
					<ContactInfo 
						location="Schweiz"
						address={[
							"Schlösslihalde 27",
							"6006 Luzern"
						]}
						person="Maik Hösel"
						phone="+41 78 4046011"
						email="maik.hoesel@ds-gesellschaft.ch"
					/>
				</div>
				<form
					onSubmit={onSubmit}
					className="grow flex flex-col md:m-10 z-10"
				>
					<div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-2 gap-4 xl:mr-52">
						<InputField
							value={name}
							placeHolder="Name"
							onChange={setName}
							type="text"
						/>
						<InputField
							value={telefon}
							placeHolder="Telefon"
							onChange={setTelefon}
							type="text"
						/>
						<InputField
							value={email}
							placeHolder="E-Mail"
							onChange={setEmail}
							type="email"
						/>
						<InputField
							value={company}
							placeHolder="Firma"
							onChange={setCompany}
							type="text"
						/>
					</div>
					<textarea
						value={message}
						onChange={event => setMessage(event.target.value)}
						placeholder="Ihre Nachricht"
						rows={8}
						className="no-scrollbar mt-4 flex-1 rounded-xl border-none bg-ds-gray-light p-2 pl-4 transition duration-500 focus:border-ds-yellow focus:ring-ds-yellow"
					/>

					<button
						type="submit"
						className={
							"ml-auto mt-4 flex w-36 items-center justify-between rounded-2xl py-1 px-7 " +
							"bg-ds-yellowsend hover:bg-ds-orange hover:scale-105 " +
							"transition ease-in-out delay-100"
						}
					>
						{sending ? "Wird gesendet..." : "Senden"}
						<ArrowRightIcon className="h-5 w-5" />
					</button>
				</form>
			</div>
			<div className="absolute inset-0 pointer-events-none mt-8 ml-24 lg:ml-44 hidden text-6xl text-[9rem] lg:text-[12rem] font-semibold text-white opacity-[0.07] md:block">
				<h2>Kontakt</h2>
			</div>
		</div>
	);
};

export default Contact;
